<template>
	<div class="main-body p-0">
		<div class="inner-wrapper">
			<div class="inner-main">
				<page-header :propButtons="page.buttons" :propConfig="page.header" :propModel="dataModel"></page-header>
				<div class="inner-main-body">
					<page-body
						:propHeader="dataTable.header"
						:propBody="dataTable.body"
						:propModel="dataModel"
						@action-next-pagination="mtd_nextPagination"
						@development-interface="mtd_goToDevelopmentInterface">
						<template slot="content-filter">
							<component :is="filter_component" @emit-init-pagination="mtd_initPagination" :prop-execute="executeFilter" :page="dataTable.header.page"></component>
						</template>
					</page-body>
				</div>
			</div>
		</div>

		<modal v-for="modal in modals" :key="modal.id" class="bg-black-trans" :id="modal.id" :propConfig="modal.config" @action-close="mtd_closeModal(modal.id)">
			<!-- <template v-if="modal.id == 'test'">
				<component
					:is="modal.slot.component"
					slot="content-modal"
					:propInfo="modal.slot"
					:modal_id="modal.id"
					@onload="mtd_componentOnload(modal.id)"
					@success="mtd_componentSuccess"
					@error="mtd_componentError"
					@close="mtd_closeModal(modal.id)"></component>
			</template> -->
		</modal>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import MainFilter from './components/MainFilter';

export default {
	components: {
		MainFilter,
	},
	data() {
		return {
			filter_component: 'main-filter',
			executeFilter: 0,
			dataModel: {
				getter: 'postulant_requirements/requirements_index',
				prefixPermission: '',
				alias: 'Mis postulaciones',
				icon: 'ri-file-copy-2-line',
			},
			page: {
				header: {
					menu: [],
				},
				buttons: {
					create: false,
					import: false,
					export: false,
				},
			},
			dataTable: {
				header: {
					title: 'Mis postulaciones',
					filter: false,
					page: 1,
				},
				body: {
					cols: [
						{
							name: 'requirement_number',
							alias: 'Requerimiento',
							width: '',
						},
						{
							name: 'enterprise_denomination',
							alias: 'Empresa',
							width: '',
						},
						{
							name: 'limit_date',
							alias: 'Fecha limite de las evaluaciones',
							width: '',
						},
						{
							name: 'job_position_name',
							alias: 'Puesto de trabajo',
							width: '',
						},
					],
					buttons: {
						edit: false,
						remove: false,
						width: '',
					},
					myButtons: [
						{
							text: "<i class='fas fa-file-signature'></i> Rendir evaluaciones",
							class: 'btn-xs btn-primary',
							tooltip: 'Muchos exitos',
							component: 'development-interface',
							can: '',
							showIf: {
								limit_date_state: true,
							},
						},
					],
					sonWindow: {
						component: null,
						row: null,
					},
				},
			},
			modals: [
				// {
				// 	id: 'test',
				// 	slot: {
				// 		component: null,
				// 		data: {
				// 			row: null,
				// 			buttonSubmit: null,
				// 			method: null,
				// 		},
				// 	},
				// 	config: {
				// 		title: null,
				// 		id: 'test',
				// 		idHash: '#test',
				// 		size: null,
				// 		scroll: true,
				// 		position: '',
				// 	},
				// },
			],
		};
	},
	computed: {
		...mapGetters(['url_api']),
	},
	methods: {
		...mapActions(['get', 'post', 'execute_commit', 'update']),
		mtd_componentOnload: function (modal_id) {
			window.$(`#${modal_id}`).modal('show');
		},
		mtd_componentSuccess: function ({ component, res, action, modal_id }) {
			switch (component) {
				case 'main-form':
					if (action == 'update') {
						this.execute_commit({ mutation: 'USERS_INDEX__UPDATE_RESOURCE', payload: { user: res.user } });
					}
					this.mtd_closeModal(modal_id);
					break;
				default:
					window.message('Warning', `No existe el modal ${component}`);
					break;
			}
		},
		mtd_componentError: function () {},
		mtd_closeModal: function (modal_id) {
			window.$(`#${modal_id}`).modal('hide');
			this.modals.find(el => el.id == modal_id).slot = {
				component: null,
				data: {
					row: null,
					buttonSubmit: null,
					method: null,
				},
			};
		},
		mtd_nextPagination: function (page) {
			this.dataTable.header.page = page;
		},
		mtd_initPagination: function () {
			this.dataTable.header.page = 0;
		},

		mtd_goToDevelopmentInterface: function (item) {
			this.$router.push({ name: 'postulant-evaluation-development', params: { evaluation_id: item.id } });
		},
	},
	beforeDestroy() {
		//this.execute_commit({ mutation: 'coordinator_requirements/REQUIREMENTS_INDEX__CLEAR_ALL' });
	},
};
</script>
