<template>
	<div class="row">
		<div class="form-group col-md-5">
			<label for="filter_text">Buscar</label>
			<div class="input-group">
				<input v-model="text" @keypress.enter="mtd_executeFilter" class="form-control form-control-sm" id="filter_text" type="text" />
				<button @click="mtd_executeFilter" class="btn btn-xs btn-primary"><i class="fas fa-search"></i> Filtrar</button>
			</div>
		</div>
	</div>
</template>
<script>
/** Vuex */
import { mapActions, mapGetters } from 'vuex';
export default {
	props: ['prop-execute', 'page'],
	data() {
		return {
			requirements: {},
			text: null,
		};
	},
	created() {
		this.mtd_executeFilter();
	},
	computed: {
		...mapGetters(['url_api']),
	},
	watch: {
		propExecute: function (newValue) {
			if (newValue) this.mtd_executeFilter();
		},
		page: function (newValue) {
			if (newValue) this.mtd_executeFilter();
		},
	},
	methods: {
		...mapActions(['get', 'execute_commit']),
		mtd_executeFilter: function (type = null) {
			let pagination = null;
			type == 'new' ? (pagination = 1) : (pagination = this.page);

			this.get({
				url: `${this.url_api}/requirements/index?page=${pagination}&text=${this.text == null ? '' : this.text}`,
				mutation: 'postulant_requirements/REQUIREMENTS_INDEX__SET_ALL',
			});
		},
	},
};
</script>
